.navButtons {
  margin: 30px auto;

  width: 70%;
  height: 45px;

  z-index: 10;

  text-align: center;
  line-height: 45px;
  font-size: 20px;
  font-weight: 600;
  color: white;


  .navButtons-select {
    float: left;

    width: 60px;
    height: 45px;

    background-color: #111111;

    overflow: block;
  }
  
  .navButtons-filter {
    float: left;

    width: 60px;
    height: 45px;

    text-align: center;
    font-size: 20px;
    font-weight: 600;

    cursor: pointer;
 
    background-color: #c4c4c4;
  }

  .navButtons-login {
    float: right;
    margin-left: 30px;

    width: 100px;
    height: 45px;
 
    background-color: #111111;
    border: 1px solid #111111;
    box-sizing: border-box;
    border-radius: 10px;
   
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: white;

    cursor: pointer;
  }
}

.navContainer {
  margin: 30px auto;
  
  width: 70%;
  height: 60px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  
  z-index: 10;

  .navContainer-profile {
    margin-top: 20px; 

    width: 200px;

    align-items: center;
    text-align: center;

    font-size: 18px;

  }

  .navContainer-name {
    margin-top: 20px; 

    width: 132px;

    align-items: center;
    text-align: right;

    font-size: 18px;
  }

  .navContainer-level {
    margin-top: 20px; 

    width: 103px;

    align-items: center;
    text-align: center;

    font-size: 18px;
  }

  .navContainer-rank {
    margin-top: 20px; 

    width: 137px;

    align-items: center;
    text-align: center;

    font-size: 18px;
  }

  .navContainer-KD {
    margin-top: 20px; 

    width: 125px;

    align-items: center;
    text-align: center;

    font-size: 18px; 
  }

  .navContainer-WL {
    margin-top: 20px; 

    width: 174px;

    align-items: center;
    text-align: center;

    font-size: 18px; 
  }
}

.navContainer-not {
  margin: 30px auto;
  
  width: 70%;
  height: 60px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  
  z-index: 10;

  .navContainer-profile {
    margin-top: 20px; 

    width: 200px;

    align-items: center;
    text-align: center;

    font-size: 18px;

  }

  .navContainer-name {
    margin-top: 20px; 

    width: 132px;

    align-items: center;
    text-align: right;

    font-size: 18px;
  }

  .navContainer-level {
    margin-top: 20px; 

    width: 103px;

    align-items: center;
    text-align: center;

    font-size: 18px;
  }

  .navContainer-rank {
    margin-top: 20px; 

    width: 137px;

    align-items: center;
    text-align: center;

    font-size: 18px;
  }

  .navContainer-KD {
    margin-top: 20px; 

    width: 125px;

    align-items: center;
    text-align: center;

    font-size: 18px; 
  }

  .navContainer-WL {
    margin-top: 20px; 

    width: 174px;

    align-items: center;
    text-align: center;

    font-size: 18px; 
  }
}